import apiClient from "./apiClient";
import type { Sale } from "~/types/trade";

export default {
  async closePosition(positionData: Sale) {
    const apiHeaders = await useApiHeaders();
    const { data } = await useFetch("/api/sale", {
      method: "POST",
      body: JSON.stringify(positionData),
      ...apiHeaders,
    });
    return data.value.data;
  },
  async getSalesByTrade(tradeId: string) {
    const { data } = await apiClient.get("/api/sale/trade/" + tradeId);
    return data.data;
  },
  async deleteSale(saleId: string) {
    const apiHeaders = await useApiHeaders();
    const { data } = await $fetch("/api/sale/" + saleId, {
      method: "DELETE",
      ...apiHeaders,
    });
    return data;
  },
  async getAll() {
    const apiHeaders = await useApiHeaders();
    const { data } = await $fetch("/api/sale/", {
      ...apiHeaders,
    });
    return data;
  },
  async getClosedSum() {
    const { data } = await apiClient.get("/api/sale/sum");
    return data.data;
  },
  async getTax(year: number) {
    const apiHeaders = await useApiHeaders();
    const { data } = await $fetch(`/api/sale/tax/${year}`, {
      ...apiHeaders,
    });
    return data;
  },
  async getPnl(sale: Sale) {
    const apiHeaders = await useApiHeaders();
    const { data } = await useFetch("/api/sale/pnl", {
      method: "POST",
      body: JSON.stringify(sale),
      ...apiHeaders,
    });
    return data.value.data;
  },
};
