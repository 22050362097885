import axios from "axios";

const config = useRuntimeConfig();

const apiClient = axios.create({
  baseURL: config.baseURL,
  withCredentials: true,
});

export default apiClient;
